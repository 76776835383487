import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

export default function StudiliveFaqs() {
  const drupalData = useStaticQuery(graphql`
    query studiLiveFaq {
      allNodeFaqs {
        nodes {
          field_faqs_categories
          field_faqs_question
          field_faqs_answer {
            processed
          }
        }
      }
    }
  `)

  const data = drupalData.allNodeFaqs
  const [activeQues, setActiveQues] = useState(-1)
  useEffect(() => {
    document
      .getElementById("subscription")
      .addEventListener("click", function () {
        let category = document.getElementById("id_subscription")
        category.classList.remove("display_hide")
        category.classList.add("display_show")

        document.getElementById("id_courses").classList.remove("display_show")
        document.getElementById("id_courses").classList.add("display_hide")

        document
          .getElementById("id_cancelation")
          .classList.remove("display_show")
        document.getElementById("id_cancelation").classList.add("display_hide")

        document.getElementById("id_policies").classList.remove("display_show")
        document.getElementById("id_policies").classList.add("display_hide")

        document.getElementById("id_services").classList.remove("display_show")
        document.getElementById("id_services").classList.add("display_hide")

        document.getElementById("subscription").style.cssText =
          "font-weight:600;color: #00ABFF;"
        document.getElementById("policies").style.cssText =
          "fontWeight:100;color: #525252;"
        document.getElementById("cources").style.cssText =
          "fontWeight:100;color: #525252;"
        document.getElementById("cancelation").style.cssText =
          "fontWeight:100;color: #525252;"
        document.getElementById("services").style.cssText =
          "fontWeight:100;color: #525252;"
      })

    document
      .getElementById("cancelation")
      .addEventListener("click", function () {
        let category = document.getElementById("id_cancelation")
        category.classList.remove("display_hide")
        category.classList.add("display_show")

        document.getElementById("id_courses").classList.remove("display_show")
        document.getElementById("id_courses").classList.add("display_hide")

        document
          .getElementById("id_subscription")
          .classList.remove("display_show")
        document.getElementById("id_subscription").classList.add("display_hide")

        document.getElementById("id_policies").classList.remove("display_show")
        document.getElementById("id_policies").classList.add("display_hide")

        document.getElementById("id_services").classList.remove("display_show")
        document.getElementById("id_services").classList.add("display_hide")

        document.getElementById("cancelation").style.cssText =
          "font-weight:600;color: #00ABFF;"
        document.getElementById("policies").style.cssText =
          "fontWeight:100;color: #525252;"
        document.getElementById("cources").style.cssText =
          "fontWeight:100;color: #525252;"
        document.getElementById("subscription").style.cssText =
          "fontWeight:100;color: #525252;"
        document.getElementById("services").style.cssText =
          "fontWeight:100;color: #525252;"
      })

    document.getElementById("policies").addEventListener("click", function () {
      let category = document.getElementById("id_policies")
      category.classList.remove("display_hide")
      category.classList.add("display_show")

      document.getElementById("id_courses").classList.remove("display_show")
      document.getElementById("id_courses").classList.add("display_hide")

      document.getElementById("id_cancelation").classList.remove("display_show")
      document.getElementById("id_cancelation").classList.add("display_hide")

      document
        .getElementById("id_subscription")
        .classList.remove("display_show")
      document.getElementById("id_subscription").classList.add("display_hide")

      document.getElementById("id_services").classList.remove("display_show")
      document.getElementById("id_services").classList.add("display_hide")

      document.getElementById("policies").style.cssText =
        "font-weight:600;color: #00ABFF;"
      document.getElementById("cancelation").style.cssText =
        "fontWeight:100;color: #525252;"
      document.getElementById("cources").style.cssText =
        "fontWeight:100;color: #525252;"
      document.getElementById("subscription").style.cssText =
        "fontWeight:100;color: #525252;"
      document.getElementById("services").style.cssText =
        "fontWeight:100;color: #525252;"
    })

    document.getElementById("cources").addEventListener("click", function () {
      let category = document.getElementById("id_courses")
      category.classList.remove("display_hide")
      category.classList.add("display_show")

      document.getElementById("id_policies").classList.remove("display_show")
      document.getElementById("id_policies").classList.add("display_hide")

      document.getElementById("id_cancelation").classList.remove("display_show")
      document.getElementById("id_cancelation").classList.add("display_hide")

      document
        .getElementById("id_subscription")
        .classList.remove("display_show")
      document.getElementById("id_subscription").classList.add("display_hide")

      document.getElementById("id_services").classList.remove("display_show")
      document.getElementById("id_services").classList.add("display_hide")

      document.getElementById("cources").style.cssText =
        "font-weight:600;color: #00ABFF;"
      document.getElementById("cancelation").style.cssText =
        "fontWeight:100;color: #525252;"
      document.getElementById("policies").style.cssText =
        "fontWeight:100;color: #525252;"
      document.getElementById("subscription").style.cssText =
        "fontWeight:100;color: #525252;"
      document.getElementById("services").style.cssText =
        "fontWeight:100;color: #525252;"
    })

    document.getElementById("services").addEventListener("click", function () {
      let category = document.getElementById("id_services")
      category.classList.remove("display_hide")
      category.classList.add("display_show")

      document.getElementById("id_policies").classList.remove("display_show")
      document.getElementById("id_policies").classList.add("display_hide")

      document.getElementById("id_cancelation").classList.remove("display_show")
      document.getElementById("id_cancelation").classList.add("display_hide")

      document
        .getElementById("id_subscription")
        .classList.remove("display_show")
      document.getElementById("id_subscription").classList.add("display_hide")

      document.getElementById("id_courses").classList.remove("display_show")
      document.getElementById("id_courses").classList.add("display_hide")

      document.getElementById("services").style.cssText =
        "font-weight:600;color: #00ABFF;"
      document.getElementById("cancelation").style.cssText =
        "fontWeight:100;color: #525252;"
      document.getElementById("policies").style.cssText =
        "fontWeight:100;color: #525252;"
      document.getElementById("subscription").style.cssText =
        "fontWeight:100;color: #525252;"
      document.getElementById("cources").style.cssText =
        "fontWeight:100;color: #525252;"
    })
    // Mobile javascript

    document
      .getElementById("subscriptionMob")
      .addEventListener("click", function () {
        let category = document.getElementById("id_subscription")
        category.classList.remove("display_hide")
        category.classList.add("display_show")

        document.getElementById("id_policies").classList.remove("display_show")
        document.getElementById("id_policies").classList.add("display_hide")

        document
          .getElementById("id_cancelation")
          .classList.remove("display_show")
        document.getElementById("id_cancelation").classList.add("display_hide")

        document.getElementById("id_services").classList.remove("display_show")
        document.getElementById("id_services").classList.add("display_hide")

        document.getElementById("id_courses").classList.remove("display_show")
        document.getElementById("id_courses").classList.add("display_hide")
      })

    document
      .getElementById("cancellationMob")
      .addEventListener("click", function () {
        let category = document.getElementById("id_cancelation")
        category.classList.remove("display_hide")
        category.classList.add("display_show")

        document.getElementById("id_policies").classList.remove("display_show")
        document.getElementById("id_policies").classList.add("display_hide")

        document
          .getElementById("id_subscription")
          .classList.remove("display_show")
        document.getElementById("id_subscription").classList.add("display_hide")

        document.getElementById("id_services").classList.remove("display_show")
        document.getElementById("id_services").classList.add("display_hide")

        document.getElementById("id_courses").classList.remove("display_show")
        document.getElementById("id_courses").classList.add("display_hide")
      })

    document
      .getElementById("policiesMob")
      .addEventListener("click", function () {
        let category = document.getElementById("id_policies")
        category.classList.remove("display_hide")
        category.classList.add("display_show")

        document
          .getElementById("id_cancelation")
          .classList.remove("display_show")
        document.getElementById("id_cancelation").classList.add("display_hide")

        document
          .getElementById("id_subscription")
          .classList.remove("display_show")
        document.getElementById("id_subscription").classList.add("display_hide")

        document.getElementById("id_services").classList.remove("display_show")
        document.getElementById("id_services").classList.add("display_hide")

        document.getElementById("id_courses").classList.remove("display_show")
        document.getElementById("id_courses").classList.add("display_hide")
      })

    document
      .getElementById("courcesMob")
      .addEventListener("click", function () {
        let category = document.getElementById("id_courses")
        category.classList.remove("display_hide")
        category.classList.add("display_show")

        document
          .getElementById("id_cancelation")
          .classList.remove("display_show")
        document.getElementById("id_cancelation").classList.add("display_hide")

        document
          .getElementById("id_subscription")
          .classList.remove("display_show")
        document.getElementById("id_subscription").classList.add("display_hide")

        document.getElementById("id_services").classList.remove("display_show")
        document.getElementById("id_services").classList.add("display_hide")

        document.getElementById("id_policies").classList.remove("display_show")
        document.getElementById("id_policies").classList.add("display_hide")
      })

    document
      .getElementById("servicesMob")
      .addEventListener("click", function () {
        let category = document.getElementById("id_services")
        category.classList.remove("display_hide")
        category.classList.add("display_show")

        document
          .getElementById("id_cancelation")
          .classList.remove("display_show")
        document.getElementById("id_cancelation").classList.add("display_hide")

        document
          .getElementById("id_subscription")
          .classList.remove("display_show")
        document.getElementById("id_subscription").classList.add("display_hide")

        document.getElementById("id_courses").classList.remove("display_show")
        document.getElementById("id_courses").classList.add("display_hide")

        document.getElementById("id_policies").classList.remove("display_show")
        document.getElementById("id_policies").classList.add("display_hide")
      })
  })

  const toggleActiveQues = index => {
    if (activeQues == index) {
      setActiveQues(-1)
    } else {
      setActiveQues(index)
    }
  }
  return (
    <div id="faqs">
      <div className="faq_parent_container">
        <p>Frequently Asked Questions</p>
        <div className="faq_head_text"></div>
        <div className="faq_patent_class">
          <div className="row">
            <div className="col-lg-3 faq_index_table">
              <div className="categories_back">
                <span className="categories_label">Categories </span>
                <div className="faq_list_categories">
                  <dl>
                    <dt
                      className="categories_list faq_first_category"
                      id="cources"
                      style={{
                        "font-weight": "600",
                        color: "rgb(0, 171, 255)",
                      }}
                    >
                      Proposition
                    </dt>
                    <dt className="categories_list" id="subscription">
                      Product
                    </dt>
                    <dt className="categories_list" id="cancelation">
                      Pricing
                    </dt>
                    <dt className="categories_list" id="policies">
                      Payment
                    </dt>
                    <dt className="categories_list" id="services">
                      Services
                    </dt>
                  </dl>
                </div>
              </div>
            </div>
            <div className="col-lg-3 faq-radio-btton">
              <div
                className="btn-toolbar-vertical btn-group-toggle"
                data-toggle="buttons"
                style={{
                  display: "flex",
                  "margin-left": "0",
                  flexDirection: "column",
                }}
              >
                <div
                  className="btn-toolbar-vertical btn-group-toggle"
                  style={{
                    display: "flex",
                    "justify-content": "center",
                    padding: "0 12px",
                  }}
                >
                  <label className="btn btn-info col-4 active">
                    <input
                      type="radio"
                      name="options"
                      id="courcesMob"
                      autoComplete="off"
                      checked
                    />{" "}
                    Proposition
                  </label>
                  <label className="btn btn-info col-4">
                    <input
                      type="radio"
                      name="options"
                      id="subscriptionMob"
                      autoComplete="off"
                    />{" "}
                    Product
                  </label>
                  <label className="btn btn-info col-4">
                    <input
                      type="radio"
                      name="options"
                      id="cancellationMob"
                      autoComplete="off"
                    />{" "}
                    Pricing
                  </label>
                </div>
                <div
                  className="btn-toolbar-vertical btn-group-toggle"
                  style={{
                    display: "flex",
                    "justify-content": "center",
                    padding: "0 12px",
                  }}
                >
                  <label className="btn btn-info col-4">
                    <input
                      type="radio"
                      name="options"
                      id="policiesMob"
                      autoComplete="off"
                    />{" "}
                    Payment
                  </label>
                  <label className="btn btn-info col-4">
                    <input
                      type="radio"
                      name="options"
                      id="servicesMob"
                      autoComplete="off"
                    />{" "}
                    Services
                  </label>
                </div>
              </div>
            </div>
            <div className="col-lg-9 faq-qa-area">
              <div id="id_courses" className="display_show">
                <span className="categories_filter_label">Proposition</span>
                {data &&
                  data.nodes.map((item, i) => {
                    if (item.field_faqs_categories == "STUDILIVE-PROPOSITION") {
                      return (
                        <div id="accordion">
                          <div
                            className={
                              activeQues == i
                                ? "question-panel active-question-panel"
                                : "question-panel"
                            }
                            onClick={() => toggleActiveQues(i)}
                          >
                            <div className="acc-header">
                              <div className="header-text">
                                {item.field_faqs_question}
                              </div>
                              <div className="panel"></div>
                            </div>
                            <div
                              className="acc-body"
                              dangerouslySetInnerHTML={{
                                __html: item.field_faqs_answer.processed,
                              }}
                            />
                          </div>
                        </div>
                      )
                    }
                  })}
              </div>
              <div id="id_subscription" className="display_hide">
                <span className="categories_filter_label">Product</span>
                {data.nodes.map((item, i) => {
                  if (item.field_faqs_categories == "STUDILIVE-PRODUCT") {
                    return (
                      <div id="accordion">
                        <div
                          className={
                            activeQues == i
                              ? "question-panel active-question-panel"
                              : "question-panel"
                          }
                          onClick={() => toggleActiveQues(i)}
                        >
                          <div className="acc-header">
                            <div className="header-text">
                              {item.field_faqs_question}
                            </div>
                            <div className="panel"></div>
                          </div>
                          <div
                            className="acc-body"
                            dangerouslySetInnerHTML={{
                              __html: item.field_faqs_answer.processed,
                            }}
                          />
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
              <div id="id_cancelation" className="display_hide">
                <span className="categories_filter_label">Pricing</span>
                {data.nodes.map((item, i) => {
                  if (item.field_faqs_categories == "STUDILIVE-PRICING") {
                    return (
                      <div id="accordion">
                        <div
                          className={
                            activeQues == i
                              ? "question-panel active-question-panel"
                              : "question-panel"
                          }
                          onClick={() => toggleActiveQues(i)}
                        >
                          <div className="acc-header">
                            <div className="header-text">
                              {item.field_faqs_question}
                            </div>
                            <div className="panel" />
                          </div>
                          <div
                            className="acc-body"
                            dangerouslySetInnerHTML={{
                              __html: item.field_faqs_answer.processed,
                            }}
                          />
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
              <div id="id_policies" className="display_hide">
                <span className="categories_filter_label">Payment</span>
                {data.nodes.map((item, i) => {
                  if (item.field_faqs_categories == "STUDILIVE-PAYMENT") {
                    return (
                      <div id="accordion">
                        <div
                          className={
                            activeQues == i
                              ? "question-panel active-question-panel"
                              : "question-panel"
                          }
                          onClick={() => toggleActiveQues(i)}
                        >
                          <div className="acc-header">
                            <div className="header-text">
                              {item.field_faqs_question}
                            </div>
                            <div className="panel"></div>
                          </div>
                          <div
                            className="acc-body"
                            dangerouslySetInnerHTML={{
                              __html: item.field_faqs_answer.processed,
                            }}
                          />
                        </div>
                      </div>
                    )
                  }
                })}
              </div>

              <div id="id_services" className="display_hide">
                <span className="categories_filter_label">Services</span>
                {data.nodes.map((item, i) => {
                  if (item.field_faqs_categories == "STUDILIVE-SERVICE") {
                    return (
                      <div id="accordion">
                        <div
                          className={
                            activeQues == i
                              ? "question-panel active-question-panel"
                              : "question-panel"
                          }
                          onClick={() => toggleActiveQues(i)}
                        >
                          <div className="acc-header">
                            <div className="header-text">
                              {item.field_faqs_question}
                            </div>
                            <div className="panel"></div>
                          </div>
                          <div
                            className="acc-body"
                            dangerouslySetInnerHTML={{
                              __html: item.field_faqs_answer.processed,
                            }}
                          />
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
