import React, {useState, useEffect} from "react"
import Navbar from "../../components/StudiLive Comps/navbar"
import StudiLiveFooter from "../../components/StudiLive Comps/footer"
import StudiLiveMobileFooter from "../../components/StudiLive Comps/mobileFooter"
import MobileNavbar from "../../components/StudiLive Comps/mobileNavbar.js"

import "../../css/studilive/globalRules.css"
import "../../css/globals.css"
import StudiliveFaqs from "../../components/StudiLive Comps/studiLiveFaqs"
import SEO from "../../components/seo"
import useIsMobile from "../../hooks/useIsMobile"
import DatalayerEvents from "../../../services/datalayer"

export default function ContactUs(props) {
  const isMobile = useIsMobile('1023px');

  const [datalayerEvents, setdatalayerEvents] = useState(new DatalayerEvents())

  useEffect(() => {
    datalayerEvents.init();
  }, [])

  return (
    <div>
      <SEO title="Frequently Asked Questions | Studi Live" lang="en" />
      <div>
        <div className="pageBanner-studilive supportPage bannerText">
          <h1 className="displayNone">FAQs</h1>
          <h2>Support - FAQs</h2>
        </div>
      </div>
      {isMobile ? <MobileNavbar /> : <Navbar />}

      <StudiliveFaqs />

      {isMobile ? <StudiLiveMobileFooter /> : <StudiLiveFooter />}
    </div>
  )
}
